<template>

  <section class="HomepageMajorSection" :style="sectionStyle">

    <Container>

      <SectionTextBlock v-if="presentationOptions.mediaPosition !== 'above-text'"
        v-bind="sectionTextBlockProps"
        class="textChunk"
      />

      <AnyMedia class="mediaChunk" :media="content.media" />

      <SectionTextBlock v-if="presentationOptions.mediaPosition === 'above-text'"
        v-bind="sectionTextBlockProps"
        class="textChunk"
      />

      <div class="subsections">
        <SubsectionWithImage
          v-for="subsection in content.subsections || []"
          :key="subsection.heading"
          :content="subsection"
          class="subsection"
        />
      </div>

    </Container>

    <FeatureCards v-if="content.features && content.features.length > 0" :features="content.features" class="featureCards" />

  </section>

</template>

<script>
import AnyMedia from '../../../elems/AnyMedia.vue'
import FeatureCards from './FeatureCards.vue'
import SectionTextBlock from '../blocks/SectionTextBlock.vue'
import SubsectionWithImage from './SubsectionWithImage.vue'

export default {
  name: 'HomepageMajorSection',
  components: {
    AnyMedia,
    FeatureCards,
    SectionTextBlock,
    SubsectionWithImage,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    presentationOptions() {
      const presentationOptions = {
        backgroundColor: 'white',
        mediaPosition: 'below-text',
      }
      if (this.content.presentationOptions) {
        Object.assign(presentationOptions, this.content.presentationOptions)
      }
      return presentationOptions
    },
    sectionStyle() {
      const { backgroundColor } = this.presentationOptions
      return {
        backgroundColor: `var(--bg-color-${backgroundColor})`
      }
    },
    sectionTextBlockProps() {
      return {
        heading: this.content.heading,
        overview: this.content.overview,
        body: this.content.body,
        ctas: this.content.ctas,
      }
    },
  }
}
</script>

<style scoped>
.HomepageMajorSection {
  padding-top: var(--spacing-17);
  padding-bottom: var(--spacing-17);
  background-color: var(--bg-color-pale-gray);
}
.mediaChunk + .textChunk,
.textChunk + .mediaChunk {
  margin-top: var(--spacing-11);
}
.mediaChunk {
  border-radius: var(--border-radius);
  overflow: hidden;
}
.subsection {
  margin-top: var(--spacing-15);
}
.featureCards {
  margin-top: var(--spacing-11);
}
</style>
